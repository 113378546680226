import { Component, DestroyRef, inject, OnInit, signal, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AutocompleteOption, UiAutocompleteInputComponent } from '@big-direkt/ui/input';
import { ScrollService } from '@big-direkt/utils/environment';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { IconBigMediumPapierflieger, IconBigMediumSuche } from '@big-direkt/utils/icons';
import { BreakpointService, LinkListItem } from '@big-direkt/utils/shared';
import { debounce, debounceTime, distinctUntilChanged, interval, switchMap, take, tap } from 'rxjs';
import { TextfieldBaseComponent } from '../../../base-components/textfield-base/textfield-base.component';
import { FormSearchTrackingInfo } from '../../../interfaces/form-search-tracking-info';
import { type ComponentMap } from '../../../utilities/component-map/component-map';
import { ContactFormSearchService } from './contact-form-search.service';

@Component({
    selector: 'big-form-contact-form-search',
    templateUrl: './contact-form-search.component.html',
    providers: [
        provideTranslationScope('ftbContactFormSearch', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`)),
    ],
})
export class ContactFormSearchComponent extends TextfieldBaseComponent implements OnInit {
    private readonly suggestDebounceTime = 300;
    private readonly blurDebounceTimer = 100;

    private readonly destroyRef = inject(DestroyRef);
    private readonly formBuilder = inject(FormBuilder);
    private readonly scrollService = inject(ScrollService);
    private readonly breakpointService = inject(BreakpointService);
    private readonly contactFormSearchService = inject(ContactFormSearchService);
    private readonly router = inject(Router);

    public readonly iconSearch = IconBigMediumSuche;
    public readonly iconMessage = IconBigMediumPapierflieger;
    public readonly defaultErrorMessage = 'ftbContactFormSearch.required';
    public readonly defaultTopicSelectError = { required: this.defaultErrorMessage };

    @ViewChild(UiAutocompleteInputComponent) public uiAutocompleteElement?: UiAutocompleteInputComponent<string>;

    public hasProceeded = false;
    public defaultOptions: AutocompleteOption<string>[] = [];
    public wasValidated = false;
    public searchTrackingInfo = signal<FormSearchTrackingInfo>({});

    public searchQuery = this.formBuilder.control<Record<string, string> | string | undefined>('', [
        // eslint-disable-next-line @typescript-eslint/unbound-method
        Validators.required,
    ]);
    public selectedTopic = this.formBuilder.nonNullable.control('');

    public suggestionOptions = signal<AutocompleteOption<string>[]>([]);
    public resultLinks = signal<LinkListItem[]>([]);

    public errorOverrides = { required: this.defaultErrorMessage };

    public static register(): ComponentMap {
        return {
            big_webform_contact_form_search: ContactFormSearchComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }

    public ngOnInit(): void {
        if (this.control?.value) {
            this.hasProceeded = true;
        }

        this.errorOverrides.required = this.settings.attributes?.requiredError ?? this.defaultErrorMessage;

        this.contactFormSearchService
            .getPromotedContactTopics()
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                take(1),
                tap(searchResult => {
                    const mappedOptions = this.contactFormSearchService.transformToOptions(searchResult);
                    this.defaultOptions = mappedOptions;
                    this.suggestionOptions.set(this.defaultOptions);
                }),
            )
            .subscribe();

        this.searchQuery.valueChanges
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                debounce(value => interval(value ? this.suggestDebounceTime : 0)),
                distinctUntilChanged(),
                switchMap(searchTerm => this.contactFormSearchService.getSuggestions(searchTerm)),
                tap(result => {
                    if (this.searchQuery.value?.length && result) {
                        const mappedOptions = this.contactFormSearchService.transformToOptions(result);

                        this.suggestionOptions.set(mappedOptions);
                        // Required for correct form dirty state detection
                        this.control?.markAsDirty();

                        return;
                    }

                    this.selectedTopic.setValue('');
                    this.suggestionOptions.set(this.defaultOptions);
                    this.resultLinks.set([]);
                }),
            )
            .subscribe();

        this.selectedTopic.valueChanges
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                debounceTime(this.blurDebounceTimer),
                distinctUntilChanged(),
                switchMap(searchTerm => this.contactFormSearchService.getResults(searchTerm)),
                tap(result => {
                    this.searchTrackingInfo.set({
                        entryType: this.suggestionOptions().find(x => x.label === this.selectedTopic.value)
                            ? 'automatic_search_suggestion'
                            : 'manual_search_entry',
                        q: this.selectedTopic.value,
                        src: this.router.url,
                        res: result?.total ?? 0,
                        isNewSearchSubmit: true,
                    });

                    if (result) {
                        this.uiAutocompleteElement?.autocomplete?.closePanel();
                    }
                    const mappedLinks = result ? this.contactFormSearchService.mapToLinkItems(result) : [];

                    this.resultLinks.set(mappedLinks);

                    if ((mappedLinks.length === 0 && this.selectedTopic.value) || this.hasProceeded) {
                        this.confirmAndProceed();
                    }
                }),
            )
            .subscribe();

        if (this.control?.value) {
            this.searchQuery.setValue(this.control.value, { emitEvent: false });
            this.selectedTopic.setValue(this.control.value);
        }
    }

    public transformOptionValue(option: AutocompleteOption<string>): string {
        return option.label;
    }

    public confirmAndProceed(event?: Event): void {
        event?.preventDefault();
        event?.stopImmediatePropagation();

        this.control?.setValue(this.selectedTopic.value);
        if (!this.hasProceeded) {
            this.hasProceeded = true;
            const isMobile = this.breakpointService.isMobile();
            if (isMobile) {
                this.scrollService.scroll('#form_page-your_issue-issue', { focusElement: true });
            }
        }
    }

    public onKeyDown(event: Event): void {
        event.preventDefault();
        event.stopImmediatePropagation();

        this.manuallySelectTopic();
    }

    public onBlur(): void {
        this.manuallySelectTopic();
    }

    private manuallySelectTopic(): void {
        this.selectedTopic.setValue(typeof this.searchQuery.value !== 'string' ? '' : this.searchQuery.value);
    }
}
