import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { BaseControlValueAccessor } from '@big-direkt/utils/shared';
import { NgxMaskDirective, provideNgxMask, type NgxMaskConfig } from 'ngx-mask';
import { CurrencyType } from '../../models';

const maskConfig: Partial<NgxMaskConfig> = {
    validation: false,
    thousandSeparator: '.',
    suffix: ' €',
};

@Component({
    selector: 'big-ui-currency-input',
    standalone: true,
    templateUrl: './currency-input.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, FormsModule, ReactiveFormsModule, NgxMaskDirective],
    providers: [
        provideNgxMask(maskConfig),
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CurrencyInputComponent,
            multi: true,
        },
    ],
})
export class CurrencyInputComponent extends BaseControlValueAccessor<number> {
    @HostBinding('class') public classList = 'block';

    @Input() public id?: string;
    @Input() public isTouched = false;
    @Input() public isRequired = false;
    @Input() public isValid = true;
    @Input() public showValidation = false;

    @Output() public readonly blurred = new EventEmitter<void>();

    public mask = 'separator.2';
    public placeholder = '0,00 €';

    @Input() public set currencyType(currencyType: CurrencyType) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        currencyType === 'integer' ? this.setDefaultConfigInteger() : this.setDefaultConfigDecimal();
    }

    public onBlur(): void {
        this.blurred.emit();
    }

    public override onChange(value: number | string | null | undefined): void {
        super.onChange(typeof value === 'string' ? undefined : value ?? undefined);
    }

    private setDefaultConfigDecimal(): void {
        this.mask = 'separator.2';
        this.placeholder = '0,00 €';
    }

    private setDefaultConfigInteger(): void {
        this.mask = 'separator.0';
        this.placeholder = '0 €';
    }
}
