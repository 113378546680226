import { Component, type OnInit } from '@angular/core';
import { FormConfigElement } from '@big-direkt/form/contracts';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { InputBaseComponent } from '../../../base-components/input-base/input-base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-radios',
    templateUrl: './radios.component.html',
    providers: [provideTranslationScope('ftbRadios', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`))],
})
export class RadiosComponent extends InputBaseComponent implements OnInit {
    public readonly errorOverrides = { required: 'ftbRadios.required' };

    public static register(): ComponentMap {
        return {
            radios: RadiosComponent,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public static override initChildControls(): void {}

    public ngOnInit(): void {
        // eslint-disable-next-line sonarjs/no-ignored-return
        this.settings.children.forEach((s: FormConfigElement) => {
            s.helpLayerIsOpen = false;
        });
    }

    public onHelpLayerClick({ isOpen, fieldName, required }: { isOpen: boolean; fieldName: string; required: boolean }): void {
        this.eventBus.emit({
            key: 'forms_info_icon_event',
            data: {
                isActive: isOpen,
                stepName: this.pageNavigationService.getCurrentPage()?.wizardLabel,
                fieldName,
                required,
            },
        });
    }
}
