import { Component, ComponentRef, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormConfigElement } from '@big-direkt/form/contracts';
import { NestedValuesBaseComponent } from '../../../base-components/nested-value-base/nested-values-base.component';
import { FormRelationService } from '../../../services/form-relation/form-relation.service';
import { MultipleHandler } from '../../../services/multiples/multiple.handler';
import { MultiplesService } from '../../../services/multiples/multiples.service';
import { type ComponentMap } from '../../../utilities/component-map/component-map';
import { MultipleItemComponent } from '../multiple-item/multiple-item.component';
import { ComponentService } from './../../../services/component/component.service';

@Component({
    selector: 'big-form-multiple',
    template: '<ng-container appElementHost />',
    encapsulation: ViewEncapsulation.Emulated,
})
export class MultipleComponent extends NestedValuesBaseComponent implements OnInit {
    private readonly multiplesService = inject(MultiplesService);
    private readonly componentService = inject(ComponentService);
    private readonly formRelationService = inject(FormRelationService);
    private readonly createdItems: ComponentRef<MultipleItemComponent>[] = [];
    private handler!: MultipleHandler;

    // eslint-disable-next-line @typescript-eslint/class-literal-property-style
    public override get isHidden(): boolean {
        return false;
    }

    public override init(): void {
        this.handler = this.multiplesService.getHandler(this.settings);
    }

    public ngOnInit(): void {
        this.settings.children.forEach(item => {
            if (item.type === 'webform_multiple_item') {
                this.createItemComponent(item);
            }
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public static override initChildControls(): void {}

    public static register(): ComponentMap {
        return {
            big_webform_multiple: MultipleComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }

    private addItem(component: MultipleItemComponent): void {
        const item = this.handler.createItem();
        const instance = this.createItemComponent(item);
        this.formRelationService.initializeRelations(item, instance.destroyRef);
        component.isLast = false;
    }

    private createItemComponent(item: FormConfigElement): MultipleItemComponent {
        const ref = this.componentService.createComponent<MultipleItemComponent>(item);
        const { instance } = ref;
        this.componentService.insertViewChildren([item], this.elementHost?.viewContainerRef);

        ref.changeDetectorRef.detectChanges();
        this.createdItems.push(ref);

        instance.isFirst = this.elementHost?.viewContainerRef.length === 1;
        instance.isLast = true;
        instance.delete.pipe(takeUntilDestroyed(instance.destroyRef)).subscribe(this.deleteItem.bind(this));
        instance.add.pipe(takeUntilDestroyed(instance.destroyRef)).subscribe(this.addItem.bind(this));

        return instance;
    }

    private deleteItem(component: MultipleItemComponent): void {
        component.delete.unsubscribe();

        const item = this.createdItems.pop();
        item?.destroy();

        this.handler.deleteItem(component.settings);
        this.componentService.removeComponent(component.settings.id);
        this.createdItems[this.createdItems.length - 1].instance.isLast = true;
    }
}
