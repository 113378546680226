<div class="relative flex w-full">
    <mat-form-field class="ml-auto h-full absolute z-1 md:!right-[4rem] bottom-0">
        <mat-date-range-input
            #dateInput
            class="!hidden"
            [formGroup]="dateRangePickerControl"
            [rangePicker]="dateRangePicker"
            [min]="minDate()"
            [max]="maxDate()"
            [required]="isRequired()">
            <input
                matStartDate
                formControlName="start" />
            <input
                matEndDate
                formControlName="end" />
        </mat-date-range-input>
        <mat-date-range-picker
            (closed)="datePickerClose()"
            #dateRangePicker />
    </mat-form-field>

    <input
        class="w-full pr-12 z-2 form-control"
        type="text"
        placeholder="TT.MM.JJJJ - TT.MM.JJJJ"
        mask="d0.M0.0000 - d0.M0.0000"
        [attr.id]="id()"
        [attr.aria-describedby]="id() + '-error'"
        [ngClass]="{
            'is-valid': isValid(),
            'is-invalid': isInvalid(),
        }"
        (blur)="hasFocus.set(false)"
        (focus)="hasFocus.set(true)"
        [dropSpecialCharacters]="false"
        [formControl]="formControl"
        [keepCharacterPositions]="true"
        [leadZeroDateTime]="true"
        [required]="isRequired()"
        [showMaskTyped]="showMaskTyped() && hasFocus()" />

    <button
        *transloco="let t; prefix: 'uiDateRange'"
        class="absolute top-0 right-0 flex p-4 text-2xl z-2 peer-focus:border-gray-200"
        (click)="datePickerOpen($event)"
        [attr.aria-label]="t('buttonLabel')">
        <big-icon
            class="primary"
            [icon]="iconCalendar" />
    </button>
</div>
