/* eslint-disable max-lines, @typescript-eslint/naming-convention */
import { HttpEventType, type HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, Injector, effect, inject, signal, type OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UploadFile } from '@big-direkt/form/contracts';
import { MobileAppRepository } from '@big-direkt/state/mobile-app';
import { ModalService } from '@big-direkt/ui/modal';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { IconBigMediumDateiUpload, IconBigMediumDokument, IconBigMediumSchliessen } from '@big-direkt/utils/icons';
import { ExternalEventPrefix, ExternalEventService } from '@big-direkt/utils/shared';
import { TranslocoService } from '@jsverse/transloco';
import { distinctUntilChanged, lastValueFrom } from 'rxjs';
import { InputBaseComponent } from '../../../base-components/input-base/input-base.component';
import { BigFileUploadFormControl } from '../../../form-control/big-form-control';
import {
    type ExternalUploadPayload,
    type ExternalUploadStartedPayload,
    type ExternalUploadSucceededPayload,
} from '../../../interfaces/external-upload-payloads';
import { ApiService } from '../../../services/api-service/api.service';
import { ErrorMessageService } from '../../../services/error-message/error-message.service';
import { StringReplaceService } from '../../../services/string-replace/string-replace.service';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

const LIST_ERROR_KEY = 'components.file.listError';

@Component({
    selector: 'big-form-file-upload',
    templateUrl: './file-upload.component.html',
    providers: [provideTranslationScope('ftbFileUpload', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`))],
})
export class FileUploadComponent extends InputBaseComponent implements OnInit {
    private readonly destroy = inject(DestroyRef);
    private readonly injector = inject(Injector);
    private readonly modalService = inject(ModalService);
    private readonly mobileAppRepository = inject(MobileAppRepository);
    private readonly translocoService = inject(TranslocoService);

    public readonly apiService = inject(ApiService);
    public readonly errorMessageService = inject(ErrorMessageService);
    public readonly externalEventService = inject(ExternalEventService);
    public readonly httpEventTypes = HttpEventType;
    public readonly iconFile = IconBigMediumDokument;
    public readonly iconRemove = IconBigMediumSchliessen;
    public readonly iconUpload = IconBigMediumDateiUpload;
    public readonly stringReplaceService = inject(StringReplaceService);
    public readonly errorOverrides = { required: 'ftbFileUpload.required' };

    public isHovering = false;

    public declare control: BigFileUploadFormControl;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public response: any = {};
    public uploadLimit?: number;
    public hasToManyFiles = signal(false);
    public allowedFileTypes?: string[];

    public extFileUpload = false;
    private extBusyFileName?: string;

    public get isMultiple(): boolean {
        return this.uploadLimit === undefined || this.uploadLimit > 1;
    }

    public get isItemLimitReached(): boolean {
        return (
            (this.uploadLimit !== undefined && this.control.files.length === this.uploadLimit) ||
            this.control.files.filter(file => HttpEventType.Response === file.status).length === this.uploadLimit
        );
    }

    private get hasBrokenFiles(): boolean {
        let brokenFileFound = false;

        this.control.files.forEach(file => {
            if (HttpEventType.Response !== file.status || file.errorMessage !== undefined) {
                brokenFileFound = true;
            }
        });

        return brokenFileFound;
    }

    public static register(): ComponentMap {
        return {
            big_webform_transactional_file: FileUploadComponent,
            big_webform_d3_upload: FileUploadComponent,
            managed_file: FileUploadComponent,
            webform_document_file: FileUploadComponent,
            webform_image_file: FileUploadComponent,
            webform_audio_file: FileUploadComponent,
            webform_video_file: FileUploadComponent,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public static override initChildControls(): void {}

    public ngOnInit(): void {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        this.control.files = this.control.files || [];

        if (!this.settings.multiple) {
            this.uploadLimit = 1;
        } else if (typeof this.settings.multiple === 'number') {
            this.uploadLimit = this.settings.multiple;
        }

        if (this.settings.fileExtensions !== undefined) {
            this.allowedFileTypes = this.settings.fileExtensions.toLowerCase().split(' ');
        }

        effect(
            () => {
                this.registerExternalEvents(this.mobileAppRepository.deactivateNativeFileUpload());
            },
            { injector: this.injector },
        );

        this.control.valueChanges.pipe(takeUntilDestroyed(this.destroy), distinctUntilChanged()).subscribe(value => {
            if (value === (this.settings.defaultValue ?? '')) {
                this.resetFiles();
            }
        });
    }

    public toggleHover(isHovering: boolean): void {
        this.isHovering = isHovering;
    }

    public onClickFileUpload(event: Event): void {
        if (this.extFileUpload) {
            this.externalEventService.dispatch<{ name: string; url: string }>('clickFileUpload', {
                name: this.settings.id,
                url: this.apiService.buildUploadUrl(this.settings),
            });

            event.preventDefault();
        }
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    public async onFileChange(fileList: FileList | undefined): Promise<void> {
        // eslint-disable-next-line no-null/no-null
        this.control.setErrors(null);
        this.removeToManyFilesError();

        if (this.extFileUpload) {
            return;
        }

        const fileUploadPromises: Promise<UploadFile>[] = [];
        const files: File[] = Array.from(fileList ?? []);

        if (files.length === 0) {
            return;
        }

        if (this.uploadLimit && files.length > this.uploadLimit) {
            this.hasToManyFiles.set(true);
            this.control.reset();

            return;
        }

        for (const [, file] of files.entries()) {
            if (this.control.files.find(x => x.name === file.name)) {
                break;
            }

            const uploadFile: UploadFile = {
                name: file.name,
                size: file.size,
                type: file.type,
            };

            this.control.files.push(uploadFile);

            if (!this.validateFileType(uploadFile) || !this.validateFileSize(uploadFile) || !this.validateFileEncryption(uploadFile, await file.text())) {
                fileUploadPromises.push(Promise.reject(new Error('Invalid file')));
                continue;
            }

            const busyFileName = `${file.name}-${new Date().getTime()}`;
            this.pageNavigationService.setBusy(busyFileName);

            const promise: Promise<UploadFile> = lastValueFrom(this.apiService.uploadFile(uploadFile, file, this.settings));

            promise
                .then((response: UploadFile | undefined): void => {
                    if (response) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const { data }: any = response.response;

                        uploadFile.fileId = data[0].id;
                        uploadFile.downloadSrc = data[0].attributes?.source;
                        delete uploadFile.response;

                        this.trackFileUpload(uploadFile);
                    }
                })
                .catch((response: HttpErrorResponse): void => {
                    // TODO: Add error handling to API Service
                    this.errorMessageService.process(response);
                    uploadFile.errorMessage = this.errorMessageService.messages.join('<br />');
                    this.errorMessageService.reset();

                    this.trackFileUpload(uploadFile, 'failed', response.statusText);
                })
                .finally((): void => {
                    this.pageNavigationService.setIdle(busyFileName);
                });

            fileUploadPromises.push(promise);
        }

        // Check all files again to detect broken uploads
        void Promise.all(fileUploadPromises).finally((): void => {
            if (this.hasBrokenFiles && !this.control.errors) {
                this.control.setErrors({ fileUpload: this.stringReplaceService.get(LIST_ERROR_KEY) });
            }
        });
    }

    public deleteFile(index: number, shouldResetValue = true): void {
        if (HttpEventType.Response === this.control.files[index]?.status) {
            lastValueFrom(this.apiService.deleteFile(this.control.files[index], this.settings)).catch((): void => {
                /* do nothing */
            });
        }

        this.control.files.splice(index, 1);

        if (this.control.files.length === 0 && shouldResetValue) {
            this.control.reset();
        }

        if (this.hasBrokenFiles) {
            this.control.setErrors({ fileUpload: this.stringReplaceService.get(LIST_ERROR_KEY) });
        } else {
            // eslint-disable-next-line no-null/no-null
            this.control.setErrors(null);
            // eslint-disable-next-line no-null/no-null
            this.control.setValue(null);
        }

        if (!this.hasToManyFiles()) {
            return;
        }

        this.removeToManyFilesError();
    }

    public showModal(src: string, label: string): void {
        this.modalService.showEmbeddedSrc({ src, label });
    }

    private resetFiles(): void {
        if (this.apiService.submittedSuccessfully) {
            return;
        }

        this.control.files.forEach((_file, index) => {
            this.deleteFile(index, false);
        });

        this.control.files = [];
    }

    private formatBytes(bytes: number, decimals = 2): string {
        if (bytes === 0) {
            return '0 Bytes';
        }

        const k = 1024;
        const dm: number = decimals < 0 ? 0 : decimals;
        const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i: number = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    }

    private removeToManyFilesError(): void {
        this.hasToManyFiles.set(false);
    }

    private validateFileEncryption(uploadFile: UploadFile, fileContent: string): boolean {
        if (fileContent.includes('/Encrypt')) {
            uploadFile.status = HttpEventType.Response;
            uploadFile.errorMessage = this.translocoService.translate('ftbFileUpload.encrypted');

            this.trackFileUpload(uploadFile, 'failed', uploadFile.errorMessage);

            return false;
        }

        return true;
    }

    private validateFileType(uploadFile: UploadFile): boolean {
        if (!uploadFile.name) {
            throw new Error('file upload: file name is missing!');
        }

        if (this.allowedFileTypes) {
            const fileExtension: string | undefined = uploadFile.name.split('.').pop()?.toLocaleLowerCase();

            if (fileExtension === undefined) {
                throw new Error('file upload: file extension is missing!');
            }

            if (!this.allowedFileTypes.includes(fileExtension)) {
                uploadFile.status = HttpEventType.Response;
                uploadFile.errorMessage = this.stringReplaceService.get('validation.file.allowedExtensions', {
                    '%files-allowed': this.settings.fileExtensions,
                });

                this.trackFileUpload(uploadFile, 'failed', uploadFile.errorMessage);

                return false;
            }
        }

        return true;
    }

    private validateFileSize(uploadFile: UploadFile): boolean {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        if (this.settings.maxFileSize !== undefined && uploadFile.size > this.settings.maxFileSize * 1024 * 1024) {
            uploadFile.status = HttpEventType.Response;
            uploadFile.errorMessage = this.stringReplaceService.get('validation.file.maxSize', {
                '%filesize': this.formatBytes(uploadFile.size).toString(),
                '%maxsize': `${this.settings.maxFileSize.toString()} MB`,
            });

            this.trackFileUpload(uploadFile, 'failed', uploadFile.errorMessage);

            return false;
        }

        if (uploadFile.size === 0) {
            uploadFile.status = HttpEventType.Response;
            uploadFile.errorMessage = 'Achtung die Datei scheint fehlerhaft (leer) zu sein. Bitte überprüfen Sie die hochgeladene Datei.';

            this.trackFileUpload(uploadFile, 'failed', uploadFile.errorMessage);

            return false;
        }

        return true;
    }

    private trackFileUpload(uploadFile: UploadFile, validationResult: 'failed' | 'ok' = 'ok', errorMessage = ''): void {
        this.eventBus.emit({
            key: 'forms_file_upload_event',
            data: {
                validationResult,
                stepName: this.pageNavigationService.getCurrentPage()?.wizardLabel,
                fieldName: this.label,
                fileType: uploadFile.type,
                fileSize: uploadFile.size,
                errorMessage,
                required: this.control.isRequired,
            },
        });
    }

    private registerExternalEvents(deactivated: boolean): void {
        this.extFileUpload = deactivated;

        if (!deactivated) {
            return;
        }

        this.externalEventService.registerEventListener<ExternalUploadStartedPayload>('uploadStarted', this.handleExtUploadEvents);
        this.externalEventService.registerEventListener<ExternalUploadSucceededPayload>('uploadSucceeded', this.handleExtUploadEvents);
        this.externalEventService.registerEventListener('uploadFailed', this.handleExtUploadEvents);
    }

    private readonly handleExtUploadEvents: (evt: CustomEvent<ExternalUploadPayload>) => void = evt => {
        if (evt.detail.fieldName !== this.settings.id) {
            return;
        }

        switch (evt.type) {
            case `${ExternalEventPrefix.Form}.uploadStarted`:
                this.handleExtUploadStarted(evt as CustomEvent<ExternalUploadStartedPayload>);
                break;

            case `${ExternalEventPrefix.Form}.uploadSucceeded`:
                this.handleExtUploadSucceeded(evt as CustomEvent<ExternalUploadSucceededPayload>);
                break;

            case `${ExternalEventPrefix.Form}.uploadFailed`:
                this.handleExtUploadFailed();
                break;

            default:
                break;
        }
    };

    private handleExtUploadStarted(evt: CustomEvent<ExternalUploadStartedPayload>): void {
        this.extBusyFileName = `${evt.detail.fileName}-${new Date().getTime()}`;
        this.pageNavigationService.setBusy(this.extBusyFileName);

        this.control.files = [
            ...this.control.files,
            {
                fileId: '-1',
                name: evt.detail.fileName,
                size: 0,
                status: this.httpEventTypes.UploadProgress,
                type: 'EXTERNAL_FILE',
            },
        ];
    }

    private handleExtUploadSucceeded(evt: CustomEvent<ExternalUploadSucceededPayload>): void {
        // eslint-disable-next-line no-null/no-null
        this.control.setErrors(null);

        const extFileIndex = this.trySetIdleAndFindExtFileIndex();

        if (extFileIndex !== -1) {
            this.control.files[extFileIndex] = {
                ...this.control.files[extFileIndex],
                fileId: evt.detail.fileId,
                size: evt.detail.size,
                status: this.httpEventTypes.Response,
                progress: 100,
                type: evt.detail.type,
                downloadSrc: evt.detail.downloadSrc,
            };

            if (!this.validateFileType(this.control.files[extFileIndex]) || !this.validateFileSize(this.control.files[extFileIndex])) {
                this.trackFileUpload(this.control.files[extFileIndex], 'failed', this.control.files[extFileIndex].errorMessage);
                this.control.setErrors({ fileUpload: this.stringReplaceService.get(LIST_ERROR_KEY) });

                return;
            }
            this.trackFileUpload(this.control.files[extFileIndex]);
        }
    }

    private handleExtUploadFailed(): void {
        const extFileIndex = this.trySetIdleAndFindExtFileIndex();

        if (extFileIndex !== -1) {
            this.control.files[extFileIndex] = {
                ...this.control.files[extFileIndex],
                errorMessage: this.stringReplaceService.get('components.file.uploadError'),
                status: this.httpEventTypes.Response,
            };

            this.trackFileUpload(this.control.files[extFileIndex], 'failed', this.control.files[extFileIndex].errorMessage);
            this.control.setErrors({ fileUpload: this.stringReplaceService.get(LIST_ERROR_KEY) });
        }
    }

    private trySetIdleAndFindExtFileIndex(): number {
        if (this.extBusyFileName) {
            this.pageNavigationService.setIdle(this.extBusyFileName);
        }

        return this.control.files.findIndex(x => x.fileId === '-1' && x.status === this.httpEventTypes.UploadProgress);
    }
}
