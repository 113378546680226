<!-- eslint-disable @angular-eslint/template/conditional-complexity -->
<div
    *transloco="let t; prefix: 'uiAutocompleteInput'"
    class="flex items-center"
    matAutocompleteOrigin
    #autocompleteUiPopupRef="matAutocompleteOrigin">
    <div class="relative flex items-center w-full">
        <input
            #autocompleteInput
            [class]="inputStyle ?? 'form-control pr-8'"
            type="text"
            [required]="isRequired"
            [id]="id"
            [name]="name"
            [formControl]="internalAutocompleteControl"
            [placeholder]="placeholder ?? ''"
            matInput
            (input)="onInputChange($event)"
            (blur)="onBlur()"
            [matAutocomplete]="auto"
            [matAutocompleteConnectedTo]="autocompleteUiPopupRef"
            #inputControlElement
            autocomplete="off"
            [ngClass]="{
                'is-invalid': isInvalid,
                'is-valid': isValid,
            }" />
        @if ((internalAutocompleteControl.valueChanges | async) || internalAutocompleteControl.value?.length) {
            <button
                type="button"
                [class]="
                    buttonStyle ??
                    'flex w-7 h-7 justify-center items-center fill-primary dark:fill-white [&>svg]:!w-full [&>svg]:!h-auto absolute !right-3 hover:fill-primary-light'
                "
                (click)="onResetButtonClick()">
                <span class="sr-only">{{ t('emptyField') }}</span>
                <big-icon [icon]="iconClose" />
            </button>
        }
    </div>
    <ng-content></ng-content>

    <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="onOptionSelected($event)"
        [class]="autocompleteClasses()"
        [requireSelection]="requireSelection"
        [hideSingleSelectionIndicator]="true">
        @if (
            (options?.length && internalAutocompleteControl.value && internalAutocompleteControl.value.length >= minCharacters) ||
            (!internalAutocompleteControl.value && minCharacters === 0)
        ) {
            @for (option of options; track $index) {
                @if (option?.children) {
                    <mat-optgroup [label]="option.label">
                        @for (child of option.children; track child) {
                            <mat-option [value]="child">
                                <span [innerHTML]="child.label | inverseHighlight: internalAutocompleteControl.value"></span>
                            </mat-option>
                        }
                    </mat-optgroup>
                } @else {
                    <mat-option [value]="option">
                        <span [innerHTML]="option.label | inverseHighlight: internalAutocompleteControl.value"></span>
                    </mat-option>
                }
            }
        } @else {
            @if (options && options.length === 0 && internalAutocompleteControl.value && internalAutocompleteControl.value.length >= minCharacters) {
                <mat-option disabled>
                    {{ t('noResultsFound') }}
                </mat-option>
            } @else {
                <mat-option disabled>
                    {{ t('minCharacters', { minCharacters }) }}
                </mat-option>
            }
        }
    </mat-autocomplete>
</div>
