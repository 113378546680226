import { Directive, HostListener, Input, inject } from '@angular/core';
import { EventBusService } from '@big-direkt/event-bus';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[bigSearchResultTracking]',
    standalone: true,
})
export class SearchResultTrackingDirective {
    private readonly eventBus = inject(EventBusService);
    @Input('bigSearchResultTracking') public trackingInfo!: {
        eventAction: 'search_result_click' | 'form_search_result_click';
        clickRank: number;
        clickTarget: string;
        currentPage: number;
        clickLabel?: string;
    };

    @HostListener('click') public onClick(): void {
        this.eventBus.emit({
            key: 'internal_search_result_click_event',
            data: {
                eventAction: this.trackingInfo.eventAction,
                clickRank: this.trackingInfo.clickRank,
                clickTarget: this.trackingInfo.clickTarget,
                page: this.trackingInfo.currentPage,
                clickLabel: this.trackingInfo.clickLabel,
            },
        });
    }
}
