import { type FormConfigElement } from '../../models/form-config-element/form-config-element.model';
import { type SubmissionObject } from '../../models/form-submission/submission-object.model';
import { type SubmissionValueState } from '../../models/form-submission/submission-value-state.model';
import { type AccessorFn, type SubmissionValueAccessorFn } from '../drupal-webform-types';

// Input: 2023-06-15T13:53:00
export const VALUE_ACCESSOR_DATELIST: AccessorFn = value => {
    if ((value as SubmissionValueState | undefined)?.isDisabled) {
        return undefined;
    }

    return (value as string | undefined)?.split('T')[0].split('-')[0];
};

const DATE_PAD_START = 2;
const YEAR_PAD_START = 4;
const f = (x: string): string => x.padStart(DATE_PAD_START, '0');
const fy = (x: string): string => x.padStart(YEAR_PAD_START, '0');

// Input: 2023-06-15T13:53:00
export const SUBMISSION_VALUE_ACCESSOR_DATELIST: SubmissionValueAccessorFn = (v, settings) => {
    const value = v as Record<string, string | undefined> | undefined;

    if (!value || !Object.keys(value).length || Object.values(value).every(x => !x)) {
        return { [settings.name]: '' };
    }

    const {
        [`${settings.name}[year]`]: year,
        [`${settings.name}[month]`]: month,
        [`${settings.name}[day]`]: day,
        [`${settings.name}[hour]`]: hour = '00',
        [`${settings.name}[minute]`]: minute = '00',
        [`${settings.name}[second]`]: second = '00',
    } = value;

    return {
        // also emptystring should be replaced. ?? would not be enough
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        [settings.name]: `${fy(year || '1900')}-${f(month || '01')}-${f(day || '01')}T${f(hour)}:${f(minute)}:${f(second)}`,
    };
};

export const VALUE_FORMATTER_DATELIST = (v: SubmissionObject, { name }: FormConfigElement): string => {
    const value = v as Record<string, string | undefined> | undefined;
    const { [`${name}[year]`]: year, [`${name}[month]`]: month, [`${name}[day]`]: day } = value ?? {};

    if (day && month && year) {
        return `${day}.${month}.${year}`;
    }

    if (!day && month && year) {
        return `${month}.${year}`;
    }

    if (!day && !month && year) {
        return year;
    }

    if (day && month && !year) {
        return `${day}.${month}.`;
    }

    if (!day && month && !year) {
        return month;
    }

    if (day && !month && !year) {
        return day;
    }

    return '';
};
