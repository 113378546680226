import { inject, Pipe, PipeTransform } from '@angular/core';
import { WindowService } from '@big-direkt/utils/environment';

@Pipe({
    name: 'inverseHighlight',
    standalone: true,
})
export class InverseHighlightPipe implements PipeTransform {
    private readonly windowService = inject(WindowService);

    public transform(text?: string, slug?: string | null): string | undefined {
        if (!text) {
            return undefined;
        }

        if (!slug) {
            return text;
        }

        const content = this.windowService.document().createTextNode(slug);
        const highlighted = this.windowService.document().createElement('span');
        highlighted.appendChild(content);

        // escape search slug to avoid regex injection
        const regex = new RegExp(`(${slug.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')})`, 'ig');

        return text
            .split(regex)
            .filter(Boolean)
            .map(part => (regex.test(part) ? part : `<strong>${this.escape(part)}</strong>`))
            .join('');
    }

    private escape(htmlStr: string): string {
        return htmlStr.replaceAll(/&/g, '&amp;').replaceAll(/</g, '&lt;').replaceAll(/>/g, '&gt;').replaceAll(/"/g, '&quot;').replaceAll(/'/g, '&#39;');
    }
}
